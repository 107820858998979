@font-face {
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/shop/MangoNew-Regular.woff2') format('woff2');
	font-family: PrimaryFont;
	font-display: fallback;
}

@font-face {
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/shop/MangoNew-SemiBold.woff2') format('woff2');
	font-family: PrimaryFont;
	font-display: fallback;
}

@font-face {
	font-style: normal;
	font-weight: 900;
	src: url('/fonts/shop/MangoNew-Black.woff2') format('woff2');
	font-family: PromotionalFont;
	font-display: fallback;
}

@font-face {
	font-style: normal;
	font-weight: 900;
	src: url('/fonts/shop/Secondary-Regular.woff2') format('woff2');
	font-family: SecondaryFont;
	font-display: fallback;
}
